import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500 500" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >
				<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M273.000000,501.000000 
	C182.000015,501.000000 91.500046,501.000000 1.000049,501.000000 
	C1.000032,334.333405 1.000032,167.666794 1.000016,1.000138 
	C167.666565,1.000092 334.333130,1.000092 500.999756,1.000046 
	C500.999847,167.666519 500.999847,334.333038 500.999939,500.999786 
	C425.166656,501.000000 349.333344,501.000000 273.000000,501.000000 
M174.296188,451.242950 
	C193.008728,460.616394 212.576752,467.397644 233.289597,470.708221 
	C256.836700,474.471863 280.460785,474.618378 303.873596,470.367310 
	C341.315460,463.568970 374.643921,447.832611 403.264099,422.474945 
	C444.102081,386.292236 467.578949,340.948334 472.636963,286.804718 
	C476.992920,240.176331 466.690765,196.535889 441.271332,156.760071 
	C423.463196,128.894287 400.368408,106.473465 371.884064,89.877312 
	C338.376160,70.354218 302.214111,60.985859 263.182037,61.802612 
	C224.670319,62.608482 189.495697,73.443985 157.026672,93.705757 
	C123.500336,114.627342 98.607979,143.457932 81.444466,178.771973 
	C63.730110,215.219376 58.447567,253.989700 63.206520,294.056427 
	C67.144066,327.207581 79.049736,357.635864 98.096634,384.910675 
	C117.732483,413.028809 142.827927,435.348694 174.296188,451.242950 
z"/>
<path fill="#FE8135" opacity="1.000000" stroke="none" 
	d="
M173.960419,451.081543 
	C142.827927,435.348694 117.732483,413.028809 98.096634,384.910675 
	C79.049736,357.635864 67.144066,327.207581 63.206520,294.056427 
	C58.447567,253.989700 63.730110,215.219376 81.444466,178.771973 
	C98.607979,143.457932 123.500336,114.627342 157.026672,93.705757 
	C189.495697,73.443985 224.670319,62.608482 263.182037,61.802612 
	C302.214111,60.985859 338.376160,70.354218 371.884064,89.877312 
	C400.368408,106.473465 423.463196,128.894287 441.271332,156.760071 
	C466.690765,196.535889 476.992920,240.176331 472.636963,286.804718 
	C467.578949,340.948334 444.102081,386.292236 403.264099,422.474945 
	C374.643921,447.832611 341.315460,463.568970 303.873596,470.367310 
	C280.460785,474.618378 256.836700,474.471863 233.289597,470.708221 
	C212.576752,467.397644 193.008728,460.616394 173.960419,451.081543 
M249.724045,384.693207 
	C249.410004,384.507843 249.095978,384.322479 248.901062,384.143677 
	C248.901062,384.143677 248.806473,384.216370 248.928406,383.453400 
	C246.978043,379.415894 245.433212,375.055725 240.900909,373.059479 
	C240.900909,373.059479 240.804245,373.222931 240.930206,372.456024 
	C239.910751,371.331238 238.891296,370.206421 237.957535,369.012665 
	C237.957535,369.012665 237.914902,369.114075 237.984451,368.325592 
	C235.281113,363.005676 232.730515,357.599701 229.804565,352.405182 
	C228.504807,350.097717 226.447403,348.217041 224.844833,346.067810 
	C224.844833,346.067810 224.795990,346.192383 224.944534,345.382294 
	C224.637085,343.946777 224.540741,342.423523 223.986893,341.090393 
	C221.061218,334.048218 217.941086,327.086182 215.064178,320.024750 
	C214.111710,317.686951 213.682678,315.135895 212.838547,312.036987 
	C232.776398,312.036987 251.872192,312.012421 270.967346,312.111176 
	C271.900360,312.115967 273.305634,312.985199 273.680786,313.817230 
	C276.244598,319.503143 278.304596,325.431793 281.111359,330.986969 
	C282.921051,334.568695 285.723816,337.648621 288.010681,340.996216 
	C288.010681,340.996216 288.051514,340.924255 288.007324,341.679810 
	C290.741638,345.399628 293.475952,349.119415 296.138550,352.902100 
	C296.138550,352.902100 296.112274,352.810425 296.249329,353.352051 
	C296.551605,353.548889 296.853882,353.745758 297.733643,354.266418 
	C321.399963,383.147217 350.631989,398.357056 388.851746,391.013214 
	C407.281586,387.471985 422.866486,378.860535 434.901489,364.175293 
	C437.086243,361.509430 437.567078,359.404968 434.643188,357.081543 
	C433.132111,355.880798 431.799438,354.455627 430.386139,353.131836 
	C430.440002,353.655090 430.493866,354.178375 430.547729,354.701660 
	C424.329163,357.112549 418.251801,361.020844 411.862701,361.623291 
	C398.464325,362.886658 389.706299,354.573059 385.101013,343.123169 
	C378.226288,326.030914 372.171173,308.555054 366.773682,290.934143 
	C356.447876,257.223724 347.484955,223.086136 336.709351,189.526260 
	C328.314270,163.380295 312.132538,141.993576 290.306885,125.316299 
	C263.746307,105.021004 233.119385,96.165909 200.035309,94.994614 
	C190.386917,94.653030 190.377686,94.741653 191.156281,104.330811 
	C191.193710,104.791573 191.504822,105.230125 191.781570,105.906563 
	C203.370880,106.578186 210.691849,113.571121 215.169525,123.465340 
	C219.749908,133.586502 223.188614,144.218277 227.467300,154.484741 
	C229.379715,159.073486 228.550888,162.322159 225.404327,166.140305 
	C205.142899,190.726303 187.393402,217.089050 172.603210,245.277405 
	C162.351028,264.816833 153.422882,285.050964 143.208191,305.104370 
	C141.741501,309.723297 140.274811,314.342194 138.887512,319.549469 
	C138.606598,320.035156 138.325668,320.520813 137.318741,321.112427 
	C129.990356,333.614136 129.599396,348.069061 126.188141,361.646912 
	C124.276207,369.256958 124.337364,377.354248 123.383118,385.216675 
	C122.843887,389.659698 124.508301,391.977356 128.809616,391.980194 
	C168.236053,392.006226 207.662552,392.013916 247.088730,391.913239 
	C248.392197,391.909912 249.692581,390.697693 251.287811,389.562897 
	C251.509262,389.044617 251.730713,388.526337 252.474472,387.639893 
	C251.554001,386.848480 250.633530,386.057068 249.756683,385.176239 
	C249.756683,385.176239 249.815018,385.256836 249.724045,384.693207 
z"/>
<path fill="#FFFEFC" opacity="1.000000" stroke="none" 
	d="
M251.952148,388.008057 
	C251.730713,388.526337 251.509262,389.044617 250.684448,389.693604 
	C210.502289,389.823334 170.923203,389.750977 131.345078,389.914673 
	C126.565353,389.934448 125.077362,388.327484 125.705116,383.800323 
	C127.206451,372.973175 128.026260,362.022034 130.112213,351.313965 
	C132.104889,341.084595 135.350098,331.099152 138.044739,321.006500 
	C138.325668,320.520813 138.606598,320.035156 139.063278,318.963257 
	C140.793747,313.909637 142.348450,309.442169 143.903137,304.974731 
	C153.422882,285.050964 162.351028,264.816833 172.603210,245.277405 
	C187.393402,217.089050 205.142899,190.726303 225.404327,166.140305 
	C228.550888,162.322159 229.379715,159.073486 227.467300,154.484741 
	C223.188614,144.218277 219.749908,133.586502 215.169525,123.465340 
	C210.691849,113.571121 203.370880,106.578186 191.781570,105.906563 
	C191.504822,105.230125 191.193710,104.791573 191.156281,104.330811 
	C190.377686,94.741653 190.386917,94.653030 200.035309,94.994614 
	C233.119385,96.165909 263.746307,105.021004 290.306885,125.316299 
	C312.132538,141.993576 328.314270,163.380295 336.709351,189.526260 
	C347.484955,223.086136 356.447876,257.223724 366.773682,290.934143 
	C372.171173,308.555054 378.226288,326.030914 385.101013,343.123169 
	C389.706299,354.573059 398.464325,362.886658 411.862701,361.623291 
	C418.251801,361.020844 424.329163,357.112549 430.547729,354.701660 
	C430.493866,354.178375 430.440002,353.655090 430.386139,353.131836 
	C431.799438,354.455627 433.132111,355.880798 434.643188,357.081543 
	C437.567078,359.404968 437.086243,361.509430 434.901489,364.175293 
	C422.866486,378.860535 407.281586,387.471985 388.851746,391.013214 
	C350.631989,398.357056 321.399963,383.147217 297.382111,353.828125 
	C296.724487,353.196686 296.418365,353.003571 296.112274,352.810425 
	C296.112274,352.810425 296.138550,352.902100 296.112183,352.529541 
	C293.407745,348.412750 290.729614,344.668518 288.051514,340.924255 
	C288.051514,340.924255 288.010681,340.996216 288.001709,340.619568 
	C284.015533,331.847870 279.802185,323.552795 276.184692,315.005524 
	C274.855347,311.864594 273.414032,310.730774 270.039764,310.757324 
	C251.751572,310.901398 233.461533,310.806824 215.172150,310.818146 
	C213.713669,310.819061 212.255310,310.999084 209.091995,311.209015 
	C214.570633,323.413635 219.683319,334.803009 224.795990,346.192383 
	C224.795990,346.192383 224.844833,346.067810 224.793228,346.475464 
	C229.132721,354.293427 233.523804,361.703735 237.914902,369.114075 
	C237.914902,369.114075 237.957535,369.012665 237.961029,369.380920 
	C238.911087,370.907074 239.857666,372.065002 240.804245,373.222931 
	C240.804245,373.222931 240.900909,373.059479 240.862579,373.471375 
	C243.484985,377.327667 246.145721,380.772034 248.806473,384.216370 
	C248.806473,384.216370 248.901062,384.143677 248.900116,384.417023 
	C249.204468,384.879181 249.509750,385.067993 249.815018,385.256836 
	C249.815018,385.256836 249.756683,385.176239 249.795044,385.520660 
	C250.539658,386.579407 251.245911,387.293732 251.952148,388.008057 
M210.372009,208.920059 
	C208.999619,212.662720 207.417084,216.343216 206.288879,220.158096 
	C198.907700,245.116684 200.605972,270.098969 206.039719,295.138458 
	C206.964493,299.399963 208.793777,300.794952 213.090393,300.745605 
	C230.902649,300.541016 248.718643,300.668854 266.533203,300.640839 
	C267.762909,300.638885 268.992126,300.360291 270.406433,300.187775 
	C258.183838,258.048096 246.104553,216.402496 233.595230,173.274246 
	C224.402176,185.285461 216.129333,195.798599 210.372009,208.920059 
z"/>
<path fill="#E88648" opacity="1.000000" stroke="none" 
	d="
M137.681732,321.059448 
	C135.350098,331.099152 132.104889,341.084595 130.112213,351.313965 
	C128.026260,362.022034 127.206451,372.973175 125.705116,383.800323 
	C125.077362,388.327484 126.565353,389.934448 131.345078,389.914673 
	C170.923203,389.750977 210.502289,389.823334 250.537735,389.936646 
	C249.692581,390.697693 248.392197,391.909912 247.088730,391.913239 
	C207.662552,392.013916 168.236053,392.006226 128.809616,391.980194 
	C124.508301,391.977356 122.843887,389.659698 123.383118,385.216675 
	C124.337364,377.354248 124.276207,369.256958 126.188141,361.646912 
	C129.599396,348.069061 129.990356,333.614136 137.681732,321.059448 
z"/>
<path fill="#E88648" opacity="1.000000" stroke="none" 
	d="
M224.870270,345.787354 
	C219.683319,334.803009 214.570633,323.413635 209.091995,311.209015 
	C212.255310,310.999084 213.713669,310.819061 215.172150,310.818146 
	C233.461533,310.806824 251.751572,310.901398 270.039764,310.757324 
	C273.414032,310.730774 274.855347,311.864594 276.184692,315.005524 
	C279.802185,323.552795 284.015533,331.847870 288.037140,340.598175 
	C285.723816,337.648621 282.921051,334.568695 281.111359,330.986969 
	C278.304596,325.431793 276.244598,319.503143 273.680786,313.817230 
	C273.305634,312.985199 271.900360,312.115967 270.967346,312.111176 
	C251.872192,312.012421 232.776398,312.036987 212.838547,312.036987 
	C213.682678,315.135895 214.111710,317.686951 215.064178,320.024750 
	C217.941086,327.086182 221.061218,334.048218 223.986893,341.090393 
	C224.540741,342.423523 224.637085,343.946777 224.870270,345.787354 
z"/>
<path fill="#E88648" opacity="1.000000" stroke="none" 
	d="
M237.949677,368.719849 
	C233.523804,361.703735 229.132721,354.293427 224.737762,346.512878 
	C226.447403,348.217041 228.504807,350.097717 229.804565,352.405182 
	C232.730515,357.599701 235.281113,363.005676 237.949677,368.719849 
z"/>
<path fill="#E88648" opacity="1.000000" stroke="none" 
	d="
M248.867432,383.834900 
	C246.145721,380.772034 243.484985,377.327667 240.791565,373.534424 
	C245.433212,375.055725 246.978043,379.415894 248.867432,383.834900 
z"/>
<path fill="#E88648" opacity="1.000000" stroke="none" 
	d="
M143.555664,305.039551 
	C142.348450,309.442169 140.793747,313.909637 139.023590,318.669098 
	C140.274811,314.342194 141.741501,309.723297 143.555664,305.039551 
z"/>
<path fill="#E88648" opacity="1.000000" stroke="none" 
	d="
M288.029419,341.302032 
	C290.729614,344.668518 293.407745,348.412750 296.148071,352.498108 
	C293.475952,349.119415 290.741638,345.399628 288.029419,341.302032 
z"/>
<path fill="#E88648" opacity="1.000000" stroke="none" 
	d="
M240.867218,372.839478 
	C239.857666,372.065002 238.911087,370.907074 237.918182,369.415405 
	C238.891296,370.206421 239.910751,371.331238 240.867218,372.839478 
z"/>
<path fill="#E88648" opacity="1.000000" stroke="none" 
	d="
M252.213318,387.823975 
	C251.245911,387.293732 250.539658,386.579407 249.773224,385.565369 
	C250.633530,386.057068 251.554001,386.848480 252.213318,387.823975 
z"/>
<path fill="#E88648" opacity="1.000000" stroke="none" 
	d="
M296.180786,353.081238 
	C296.418365,353.003571 296.724487,353.196686 297.093384,353.666229 
	C296.853882,353.745758 296.551605,353.548889 296.180786,353.081238 
z"/>
<path fill="#E88648" opacity="1.000000" stroke="none" 
	d="
M249.769531,384.975037 
	C249.509750,385.067993 249.204468,384.879181 248.840576,384.413757 
	C249.095978,384.322479 249.410004,384.507843 249.769531,384.975037 
z"/>
<path fill="#FD8237" opacity="1.000000" stroke="none" 
	d="
M210.557327,208.586090 
	C216.129333,195.798599 224.402176,185.285461 233.595230,173.274246 
	C246.104553,216.402496 258.183838,258.048096 270.406433,300.187775 
	C268.992126,300.360291 267.762909,300.638885 266.533203,300.640839 
	C248.718643,300.668854 230.902649,300.541016 213.090393,300.745605 
	C208.793777,300.794952 206.964493,299.399963 206.039719,295.138458 
	C200.605972,270.098969 198.907700,245.116684 206.288879,220.158096 
	C207.417084,216.343216 208.999619,212.662720 210.557327,208.586090 
z"/>


</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
